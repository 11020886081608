.Contribute {
	position: fixed;
	width: 240px;
	background: white;
	color: #333;
	padding: 20px;
	border-radius:3px;
	top: 20px;
	left: 250px;
	border: 1px solid #EEE;
	z-index: 1;
	visibility: hidden;
	opacity: 0;
	transition: opacity 0.6s;
	transition-delay: 0.1s;
}

.Contribute.visible {
	opacity: 1;
	visibility: visible;
	transition-delay: 0;
}


.Contribute .CloseButton {
	margin: 0 0 10px;
}
